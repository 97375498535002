@use 'sass:meta';

@layer tailwind-base, primeng, tailwind-components;

/**
 * Inject tailwind files inside layers so that primeng can be ordered correctly in the css layers. tailwind v4 may add layers and this will need to be looked at again.
 * https://github.com/tailwindlabs/tailwindcss/discussions/6694#discussioncomment-4716568
 */

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Arial, 'Helvetica Neue', sans-serif;
}
